<template>
	<div class="home">
		<h1 class="text-2xl font-semibold text-gray-900">Dashboard</h1>
	</div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'
import { useToast } from 'vue-toastification'

export default {
	name: 'Index',
	components: {},
	setup() {
		const store = useStore()
		const router = useRouter()
		const toast = useToast()

		store
			.dispatch('user/me')
			.then(
				me => {
					//console.log('Me: ', me)
					//toast.success('Me Data fetched: ' me)
					//router.push('login')
				},
				err => {
					console.error('Alternate Error handler for me failure: ', err)
				}
			)
			.catch(err => {
				console.error('meLogout Error: ', err)
			})
	},
}
</script>
